/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cookiepopup-bg: #{$white};
    --cookiepopup-border: var(--secondary-base-color);
}

.CookiePopup {
    @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Popup-Content {
        max-width: 750px;
        border-radius: 0;
        overflow: auto;

        @include mobile {
            max-width: 90vw;
            max-height: 80vh;
            min-width: initial;
            display: flex;
            flex-direction: column;
            padding: calc(var(--popup-content-padding) * 2);

            .Popup-Header {
                display: flex;
                justify-content: flex-end;

                .Popup-CloseBtn {
                    top: 0;
                }
            }
        }
    }

    &-Content {
        font-family: $futuraBook !important;
        
        > * {
            font-family: $futuraBook !important;
        }
    }

    &-title {
        width: 100%;
        font-family: $futuraLight;
        font-size: 2.0rem;
        font-weight: inherit;
        text-transform: uppercase;
    }

    &-CTA {
       display: flex;
       justify-content: center;
       margin-top: 3rem;
    }

    &-ContentWrapper {
        padding: 0;
    }
}
