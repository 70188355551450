/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        margin-bottom: 0;
        margin-top: 0;
    }

    &-Button {
        width: 60%;
        margin: 2.4rem auto;

        @include mobile {
            width: 100%;
            margin: 2.8rem auto;
        }
    }

    &-Wrapper {
        text-align: center;
        margin-top: 8rem;

        @include mobile {
            margin-top: 4rem;
        }
    }

    &-Text {
        font-family: $futuraBook;
        font-size: 1.8rem;
        line-height: 120%;
        margin-bottom: 0;
    }
}
