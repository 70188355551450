/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

.BurgerMenu {
    order: 3;
    margin: 0 0 0 15px;
    width: 18px;
    height: 14px;

    font: inherit;
    display: inline-block;
    overflow: visible;
    cursor: pointer;
    position: relative;
    //transition-property: opacity,filter;

    @include mobile {
        align-self: center;
    }

    &_isMobileMenuOpen {
        .BurgerMenu-Inner {
            transform: translate3d(0, 5.74px, 0) rotate(45deg);

            &:before {
                transform: rotate(-45deg) translate3d(-5px, -6px, 0);
                opacity: 0;
            }

            &:after {
                transform: translate3d(0, -12.5px, 0) rotate(-90deg);
            }
        }
    }

    &-Inner {
        display: block;
        top: 0;

        &:before {
            top: 6px;
        }

        &:after {
            top: 12px;
        }
    }

    &-Inner,
    &-Inner:before,
    &-Inner:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $black;
        transition: all linear .15s;
    }
}
