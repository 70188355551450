/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --store-switcher-list-background: #fff;
    --store-switcher-title-color: #414141;
}

.StoreSwitcher {
    @include desktop {
        display: inline-block;
    }

    @include before-desktop {
        // border-bottom: 1px solid var(--primary-divider-color);
        order: 1;
        // margin-bottom: 1.2rem;
    }

    // @include mobile {
    //     margin-bottom: 1.4rem;
    // }

    select,
    select:hover,
    select:focus {
        @include before-desktop {
            border: 0;
        }
    }

    .Field {
        @include before-desktop {
            margin-top: 0;
        }
    }

    &-Title {
        color: var(--store-switcher-title-color);
        cursor: pointer;
        font-size: 2rem;
        font-family: $futuraDemi;
        text-transform: uppercase;

        @include mobile {
            font-size: 1.4rem;
        }

        &::after {
            content: '';
            position: absolute;
            right: -15px;
            top: 10px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 5px solid black;
            transition: transform 200ms ease-in-out;
            transform: rotate(180deg);
            will-change: transform;
        }

        &_isOpen {
            &::after {
                transform: rotate(0);
            }
        }
    }


    &-SubMenuIcon {
        width: 17px;
        position: absolute;
        bottom: 100%;
        right: 17px;
    }

    &-StoreList {
        background-color: var(--store-switcher-list-background);
        border: 1px solid var(--input-border-color);
        display: none;
        // right: -20px;
        position: absolute;
        top: 45px;
        left: 50%;
        max-height: 50vh;
        transform: translateX(-50%);

        &_isOpen {
            display: block;
        }
    }

    @include mobile {
        .FieldSelect::after {
            right: 0;
        }
    }
}
