/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-background-color: #fff;
    --menu-desktop-background-color: rgba(0, 0, 0, 0.5);
    --menu-desktop-height: 50px;
    --menu-item-figure-background: var(--secondary-base-color);
    --menu-item-hover-color: #000;
    --menu-second-level-color: #adadad;
    --submenu-background-color: #F6F6F6;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    display: none !important;
    &-MenuWrapper {
        padding: 1.2rem;

        @include mobile {
            padding: 1.4rem;
        }

        @include desktop {
            min-height: var(--menu-desktop-height);
            padding: 0;
            border-bottom: 1px solid $greyE7;
        }

        li {
            @include mobile {
                padding-left: 0;
            }

            &::before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-Overlay {
        background-color: var(--menu-desktop-background-color);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }

    &-MainCategories {
        @include before-desktop {
            display: flex;
            flex-direction: column;
        }

        @include desktop {
            display: block;
            z-index: 100;
            max-width: var(--content-wrapper-width);
            width: 100%;
            margin: auto;
            pointer-events: none;
            display: flex;
            justify-content: center;
        }
    }

    &-ItemList {
        display: grid;

        @include desktop {
            display: flex;
        }

        &_type {
            &_main {
                @include desktop {
                    pointer-events: auto;
                    width: min-content;
                }

                @include before-desktop {
                    order: 2;
                }
            }

            &_subcategory {
                background: var(--submenu-background-color);
                grid-auto-rows: max-content;
                padding: 0 1.2rem;

                @include mobile {
                    padding: 0 1.4rem;
                }

                @include desktop {
                    // display: grid;
                    // grid-column-gap: 200px;
                    // grid-row-gap: 40px;
                    // grid-template-columns: minmax(200px, calc(100% - 40px) minmax(200px, calc(100% - 40px);
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }
    }

    &-ItemCaption {
        &_type {
            &_main {
                font-size: 1.8rem;
                line-height: 1.2;
                text-align: left;
                font-family: $futuraDemi;

                @include mobile {
                    font-size: 2.1rem;
                }

                @include desktop {
                    font-size: 1.4rem;
                }
            }

            &_subcategory {
                font-size: 1.2rem;
                font-weight: normal;
                padding: 1.2rem 0;

                @include mobile {
                    font-size: 1.4rem;
                    padding: 1.4rem 0;
                }

                @include desktop {
                    padding-left: 0;
                }
            }
        }

        &_isLogo {
            font-size: 1.1rem;
        }

        &_isBanner {
            --button-background: var(--homepage-button-background);
            --button-border: transparent;
            --button-color: var(--homepage-button-color);
            --button-hover-background: var(--homepage-button-color);
            --button-hover-border: transparent;
            --button-hover-color: var(--homepage-button-background);
            --button-padding: 9px 49px;

            bottom: 20px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    &-ItemFigure {
        align-items: center;
        display: grid;

        &_isActive {
            @include desktop {
                position: relative;

                &::before {
                    content: '';
                    height: 2px;
                    width: 100%;
                    background: #000;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                }
            }
        }

        &_type {
            &_main {
                padding-left: 30px;
                text-transform: uppercase;

                @include before-desktop {
                    background-color: var(--menu-item-figure-background);
                    grid-auto-flow: column;
                    grid-template-columns: 50% 50%;
                    height: 100px;
                }

                @include desktop {
                    height: var(--menu-desktop-height);
                    padding-left: 0;
                    z-index: 20;
                    position: relative;

                    &::after {
                        content: '';
                        height: 2px;
                        width: 100%;
                        background: $black;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        opacity: 0;
                        transform: translateY(-50%);
                    }

                    .Menu-Link_isHovered & {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &_isBanner {
            @include desktop {
                overflow: hidden;
                width: 200px;
            }
        }

        &_isLogo {
            display: flex;

            .Image-Image {
                object-fit: cover;
            }
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    &-Item {
        @include before-desktop {
            margin-top: .6rem;
            padding-left: 0;
        }

        @include mobile {
            margin-top: .7rem;
            padding-left: 0;
        }

        @include desktop {
            margin-bottom: 0;
            padding: 0 3.2rem;
        }

        @include tablet-portrait {
            padding: 0 .5rem;
        }

        &:first-child {
            margin-top: 0;
        }

        button {
            width: 100%;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }
    }

    &-SubCategoriesWrapper {
        position: absolute;
        top: var(--header-menu-height);
        pointer-events: none;
        width: 100%;
        background-color: var(--submenu-background-color);

        @include tablet {
            top: var(--header-menu-height);
            left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: calc(var(--header-menu-height) * -1);
        }

        &_isVisible {
            pointer-events: all;
        }
    }

    &-SubCategoriesWrapperInner {
        transition: clip-path 200ms ease-in;
        will-change: clip-path;
        clip-path: inset(0 0 100% 0);
        overflow-y: auto;
        max-height: calc(100vh - var(--header-menu-height));
        max-width: var(--content-wrapper-width);
        margin: 0 auto;

        &_isVisible {
            clip-path: inset(0);
        }
    }

    &-SubCategories,
    &-Promotion {
        max-width: var(--content-wrapper-width);
        margin: auto;
    }

    &-Promotion {
        order: 3;
    }

    &-SubCategories {
        padding: 4rem 4.2rem 5rem 4.2rem;

        @include mobile {
            padding: 40px 55px 50px 1.4rem;
        }
    }

    &-SubMenu {
        background: var(--submenu-background-color);
        opacity: 0;
        pointer-events: none;
        touch-action: pan-y;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;

        @include before-desktop {
            height: 100%;
            left: 0;
            padding-top: var(--header-total-height);
            position: fixed;
            top: 0;
            width: 100%;
        }

        @include desktop {
            background: transparent;
            transform: translateX(0);
            transition-duration: 0ms;
            width: 100%;
        }

        &_isVisible {
            @include subcategory-visible;
            @include mobile {
                height: 100%;
                overflow-y: scroll;
            }

            .Menu-Link {
                @include desktop {
                    padding: 0;
                }
            }
        }

        .Menu-Link {
            @include desktop {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &-SubItemWrapper {
        padding-left: 2vw;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            border-left: 2px solid #E7E7E7;
        }

        &_isBanner {
            flex-grow: 1;
            flex-basis: 400px;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        > .Menu-Link {
            display: block;
            color: $black;
            font-family: $futuraDemi;
            font-size: 15px;
            text-transform: uppercase;
        }

        .customSubCategory {
          display: block;
          color: $black;
          font-family: $futuraDemi !important;
          font-size: 15px !important;
          text-transform: uppercase;
          margin-top: 40px !important;
        }

        .Menu-ItemFigure_type_hideOnMobile {
          @include mobile {
            display: none;
          }
        }
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemList {
                display: block;
                padding: 0;

                > .Menu-Link {
                    color: $black;
                    font-family: $futuraLight;
                    font-size: 16px;
                }

                &_isBanner {
                    display: grid;
                    grid-column-gap: 10px;
                    grid-template-columns: repeat(auto-fit, 200px);
                }
            }

            &-Link {
                margin-top: 5px;
            }
        }
    }

    &-Image {
        &_isBanner {
            @include desktop {
                padding-bottom: 100%;
            }

            img {
                object-fit: cover;
            }
        }

        &_isLogo {
            height: 44px;
            margin-right: 20px;
            width: 44px;
        }

        &_type {
            &_main {
                grid-column: 2;
                height: 100%;
                justify-self: end;
                padding-bottom: 0;
            }

            &_subcategory {
                border-radius: 50%;
                height: 3.84rem;
                padding-bottom: 0;
                width: 3.84rem;

                @include mobile {
                    height: 4.48rem;
                    width: 4.48rem;
                }
            }
        }
    }

    &-Link {
        color: inherit;
        white-space: nowrap;
        padding-left: 0;

        @include before-desktop {
            padding: 1.2rem 0;
        }

        @include mobile {
            padding: 1.4rem 0;
        }

        @include desktop {
            display: block;
            margin-right: 0;
        }

        &:hover,
        &_isHovered,
        &:focus {
            color: #000;
            text-decoration: none;
        }
    }

    &-Link + &-Link {
        margin-left: 0;
    }

    .Image {
        background: none;
    }

    &-CompareLinkWrapper {
        text-transform: uppercase;
        order: 1;
    }

    &-CompareLink {
        color: var(--body-content-color);

        &:hover {
            text-decoration: none;
        }
    }
}
