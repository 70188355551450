@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C7C7C7;
    border-radius: 6px;

    &:hover {
        background-color: #878787;
    }
  }
}