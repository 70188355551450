/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --cart-overlay-totals-background: var(--secondary-base-color);
  --cart-overlay-divider-background: #d8d8d8;
  --cart-overlay-promo-background: #fff;
  --cart-overlay-width: 400px;
}

.CartOverlay {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 496px;
  top: var(--header-total-height);
  height: auto;
  background: #fff;
  z-index: 101;
  padding: 24px 40px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E7E7E7;
  overflow: hidden !important;

  @include mobile {
    padding: 12px 20px 20px !important;
  }

  &_isVisible {
    @include after-mobile {
      //border: 1px solid var(--overlay-desktop-border-color);
    }
  }

  &-Title {
    font-size: 30px;
    font-family: $dinCond;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }

  &-Additional {
    border-top: 1px solid #0000001a;
    box-shadow: 1px -13px 20px 0 #fff;
    padding-top: 16px;
  }

  &-Empty {
    text-align: center;
  }

  &-Actions {
    display: flex;
    padding: 1.2rem;

    @include mobile {
      padding: 1.4rem;
    }
  }

  &-OutOfStockProductsWarning {
    padding: 1rem;
    display: flex;
    justify-content: center;
    background-color: var(--primary-error-color, red);
    font-size: 14px;
    font-weight: bold;
  }

  &-CheckoutButton {
    margin-left: 1.2rem;

    span {
      @include lock-button;
    }

    @include mobile {
      margin-left: 1.4rem;
      width: 100%;
      display: block;
      text-align: center;
    }

    @include after-mobile {
      flex-grow: 1;
      text-align: center;
    }
  }

  &-CartButton {
    @include mobile {
      display: none;
    }
  }

  &-Promo {
    padding: 1.2rem;
    text-align: center;
    background: var(--cart-overlay-promo-background);
    margin-bottom: 0;

    @include mobile {
      padding: 1.4rem;
      border-bottom: 1px solid var(--cart-overlay-divider-background);
      border-top: 1px solid var(--expandable-content-color);
    }

    @include after-mobile {
      padding: .84rem;
      background: var(--primary-light-color);
    }

    strong {
      margin: 0 5px;
    }
  }

  &-Items {
    flex: 1;
    overflow: hidden auto;
    padding-right: 24px;

    @include mobile {
        padding-right: 12px;
    }

    @include scrollbar;
  }

  &-SubmitButton {
    background-color: #0a0a0a;
    color: #fff;
    font-family: $futuraDemi;
    font-size: 15px;
    text-transform: uppercase;
    padding: 7px 20px;
    width: 100%;
    letter-spacing: 0.75px;
    border: 2px solid #0a0a0a;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3 ease, color 0.3 ease;
    font-weight: initial;
    margin-top: 16px;

    &:hover, &:focus {
      background-color: #FFF;
      color: #000;
    }
  }

  &-Total {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    font-family: $futuraDemi;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-Comment {
    width: 100%;
    height: 80px;
    border: 1px solid #0000001a;
    font-size: 14px;
  }

  &-DiscountCoupon {
    &::after {
      content: ':';
      font-weight: normal;
    }
  }

  .closeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      font-size: 20px;
      padding-top: 2px;
    }

    button {
      font-family: $futuraDemi;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .error-border {
    border: 2px solid orangered!important;
    &::placeholder {
      color: orangered;
      opacity: 1;
    }
  }
}
