/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
}

.MyAccountMyWishlist {
    --myaccount-wihslist-products-margin-bottom: 0;
    height: 94%;
    width: 100%;

    @include mobile {
        height: 100%;
    }

    &-Products {
        display: flex;
        flex-direction: column;
        height: 100%;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-auto-rows: max-content;
        // grid-column-gap: 2.4rem;

        // @media (min-width: 1370px) {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @include tablet {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @include mobile {
        //     grid-column-gap: 2.8rem;
        //     grid-template-columns: 1fr;
        //     border: 0;
        //     margin: 0 calc(-1 * var(--my-account-wrapper-padding-mobile));
        // }

        // @include after-mobile {
        //     grid-column: 2;
        // }

        // @include before-desktop {
        //     margin-bottom: var(--myaccount-wihslist-products-margin-bottom);
        // }
    }

    &-ActionBar {
        display: flex;
        padding: 0 0 1.2rem;
        justify-content: flex-end;

        @include after-mobile {
            position: absolute;
            top: -55px;
            right: 0;
        }

        @include mobile {
            position: fixed;
            left: 0;
            bottom: calc(var(--navigation-tabs-height) - 6rem);
            width: 100%;
            padding: 2.2rem 0;
            background-color: var(--my-account-content-background);
            display: block;
        }
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &-NoProductsButton {
        margin-top: 1.2rem;

        @include mobile {
            margin-top: 1.4rem;
        }
    }

    &-Button {
        @include after-mobile {
            margin-right: 1rem;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ClearWishlistButton {
        $color: #ed3f3f;
        font-weight: bold;
        text-transform: uppercase;

        @include after-mobile {
            order: 1;
            margin-left: 15px;
        }

        &,
        &:not([disabled]):focus,
        &:not([disabled]):hover {
            color: $color;
        }

        &.Button {
            width: auto;
        }
    }

    &-ShareWishlistButton {
        @include share-button;

        height: 0;
        margin: 1.4rem 2rem 0 0;

        &[disabled] {
            @include button-disabled();
        }
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: $futuraDemi;
        font-size: 14px;
        text-transform: uppercase;

        &-nbWrapper {
            display: flex;
            align-items: center;
        }

        &-closeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .icon-favoris-empty,
        .icon-favoris-fill,
        .icon-favoris, .icon-close {
            font-size: 20px;
            margin-right: 10px;
        }

        .icon-close {
            font-size: 28px;
        }
    }

    &-sideBarTitle {
        font-size: 30px;
        font-family: $dinCond;
        text-transform: uppercase;
        margin: 25px 0 10px;
        text-align: center;
    }

    .MyAccountMyWishlist-ActionBar {
        display: block !important;
        position: static;
        top: initial;
        right: initial;

        .MyAccountMyWishlist-Button, .MyAccountMyWishlist-ClearWishlistButton {
            display: none;
        }

        .Button {
            background-color: $black;
            color: $white;
            font-family: $futuraDemi;
            font-size: 15px;
            text-transform: uppercase;
            padding: 7px 20px;
            border: none;
            width: 100%;
            letter-spacing: 0.75px;
            border: 2px solid $black;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.3s ease, color 0.3s ease;
            font-weight: initial;

            &:hover {
                background-color: $white;
                color: $black;
            }

            @include desktop {
                bottom: 30px;
            }
        }
    }

    &-productsWrapper {
        overflow-y: auto;
        flex: 1;

        &::-webkit-scrollbar{
            width: 2px;
            height: 2px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 30px;
        }
        &::-webkit-scrollbar-track{
            border-radius: 10px;
        }

        @include desktop {
            margin-bottom: 10px;
        }
    }
}
