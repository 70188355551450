/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    // @include before-desktop {
    //     flex-grow: 1;
    //     opacity: 0;
    //     pointer-events: none;
    // }

    @include desktop {
        text-align: right;
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;
    }

    &_isActive {
        --search-bar-width: 27vw;
        --search-field-border-color: var(--primary-light-color);
    }

    &-Wrapper {
        padding-left: 22px;
        border-left: 1px solid $black;
        display: flex;
        align-items: center;
        position: relative;

        // @include desktop {
        //     margin-right: 1.2rem;
        // }

        @include before-desktop {
            padding-left: 0;
            border: none;

            &:after {
                display: none;
                content: '';
                position: fixed;
                top: 70px;
                left: 0;
                width: 100vw;
                height: calc(100vh - 70px);
                background: rgba($black, 0.7);
                z-index: -1;
                opacity: 0;
                pointer-events: none;
                transition: opacity ease-in-out .5s;
            }

            .SearchOverlay-Results {
                background-color: #f6f6f6;
                left: 100%;
                transition: left ease-in-out .5s;
            }
        }

        &_isVisible {
            @include before-desktop {
                .SearchOverlay-Results {
                    left: 0 !important;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &-Results {
        white-space: nowrap;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
        font-family: $futuraDemi;
        color: rgba($black, .3);
        letter-spacing: 0.6px;
        font-size: 1.2rem;
        font-weight: initial;

        @include before-desktop {
            display: none;
        }
    }

    &-Container {
        width: 100%;
        position: fixed;
        // HEADER FIXED HEIGHT
        top: 70px;
        left: 100%;
        pointer-events: none;
        background-color: $white;
        padding: 1.4rem;
        border-bottom: 1px solid $black;
        transition: left ease-in-out .5s;
        box-sizing: border-box;

        @include mobile {
            border-bottom: 1px solid $greyE7;
        }

        &_isVisible {
            left: 0px;
            pointer-events: all;
        }

        & > .search-icon {
          display: none;
        }

        @include mobile {
            max-width: 100vw;

            & > .search-icon {
              display: inline;
              margin-right: 1rem;
            }

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    &-Input {
        transition-property: opacity, background-color;
        transition-duration: 200ms;
        background-color: var(--search-field-background);
        border-color: transparent;
        border-radius: 15px;
        border-width: 1px;
        font-size: 1.6rem;
        max-width: 0;
        min-width: 0;
        opacity: 1;
        width: 100%;
        caret-color: $black;
        padding: {
            bottom: 5px;
            left: 10px;
            top: 4px;
        };

        @include before-desktop {
            background-color: transparent;
            border-radius: 0;
            font-size: 1.4rem;
            font-family: $futuraLight;
            margin: 0;
            max-width: 100%;
            width: 100%;
            --input-color: var(--search-field-color);
        }

        @include desktop {
            background-color: transparent;
            border: 0;
            // border-bottom: 1px solid var(--search-input-desktop-border-color);
            border-radius: 0;
            font-size: 1.4rem;
            font-family: $futuraLight;
            // margin: 4.8rem 3.6rem 0 0;
            margin: 0;
            max-width: 100%;
            // padding-left: 3.6rem;
            // width: calc(100% - 7.2rem);
            width: 100%;
            // padding-bottom: 7px;
            padding: 0 22px 0 0;
        }

        &:focus,
        &:active {
            --input-color: var(--search-field-color);

            @include mobile {
              border: 0;
            }

            @include desktop {
                border: 0;
                // border-bottom: 1px solid var(--search-input-desktop-border-color);
            }
        }
    }

    &-Placeholder {
        font-size: 1.6rem;
        height: min-content;
        left: calc(50% - 5px);
        padding-left: 20px;
        pointer-events: none;
        position: absolute;
        top: 5px;
        transform: translate3d(-50%, 0, 0);
        visibility: hidden;
        width: min-content;

        @include search-icon;

        &::before {
            bottom: 0;
            left: 0;
            margin: auto;
            top: 0;
            transform: translateY(-2px);
        }

        &_isActive {
            left: 0;
            opacity: .7;
            padding-left: 0;
            transform: translate3d(10px, 0, 0);

            &::before {
                opacity: 0;
            }
        }

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-IconWrapper {
        display: flex;

    }

    &-SearchIcon,
    &-CloseIcon {
        cursor: pointer;
        font-size: 16px;

        // @include desktop {
        //     height: 24px;
        //     width: 16px;
        // }
    }

    &-SearchIcon {
        display: flex;
        align-items: center;

        > * {
            pointer-events: none;
        }

        .SearchField-searchTxt {
            margin-left: 10px;
            font-size: 14px;
            font-family: $futuraLight;
        }
        // @include search-icon;

        // &::before {
        //     box-shadow:
        //         6px 6px 0 -7px var(--header-color),
        //         7px 7px 0 -7px var(--header-color),
        //         8px 8px 0 -7px var(--header-color),
        //         9px 9px 0 -7px var(--header-color),
        //         10px 10px 0 -7px var(--header-color),
        //         11px 11px 0 -7px var(--header-color);
        //     height: calc(16px - var(--header-icon-stroke-width) * 2);
        //     left: 0;
        //     top: 2px;
        //     width: calc(16px - var(--header-icon-stroke-width) * 2);
        // }
    }

    &-CloseIcon {

        &::before {
            content: "\e903";
            font-size: 2.4rem;
            font-weight: bold;
        }

        // @include close-button;

        // &::before,
        // &::after {
        //     height: 22px;
        //     left: 8px;
        //     top: 2px;
        //     width: 2px;
        // }
    }

    &-SearchWrapper {
        background: var(--search-field-visible-background);
        // height: 100%;
        // max-width: 360px;
        // position: fixed;
        // right: 0;
        // top: calc(var(--header-total-height) + 3px)  ;
        // transform: translateX(100%);
        transition: transform 300ms ease-in;
        width: 100%;
        //border-left: 1px solid var(--primary-divider-color);
        padding-left: 20px;
        position: relative;
        &_isVisible {
            // transform: translateX(0);
        }
    }

    &-SearchInnerWrapper {
        .SearchField-SearchIcon {
            left: 4.2rem;
            position: absolute;
            top: 4.8rem;

            @include mobile {
                left: 4.9rem;
                top: 5.6rem;
            }
        }
    }
}
